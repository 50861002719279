import axios from 'axios'
import { CONFIG } from '../../constants'

export const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/storage`,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const shouldUseMultipart = (file) => {
    if (!file.size) {
        throw new Error(
            'File size is required to check if it should use multipart',
        )
    }
    console.log('shouldUseMultipart', file.size, CONFIG.MULTIPART_SIZE_MINIMUM)
    return file.size >= CONFIG.MULTIPART_SIZE_MINIMUM
}
