import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '../store/slices/authSlice'

/**
 * @description Hook to get basic information about the user
 * @returns {object} - The user's id and feature flags
 */
const useUser = () => {
    const { userId } = useSelector((state) => state.auth)
    const flags = useSelector(selectFeatureFlags)
    return {
        userId,
        ...flags,
    }
}

export default useUser
