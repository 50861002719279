import CONFIG from './config'
export { CONFIG }

export const currencyTypes = {
    USDC: {
        label: 'USDC',
        factor: 1e2,
    },
    wSOL: {
        label: 'wSOL',
        factor: 1e9,
    },
}

export const uploadTypes = {
    ADDONS: 'addons',
    BLEND_FILE: 'blendFile',
}

export const STATUS = {
    IDLE: 'idle',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
}

export const JOB_STATUS = {
    RENDERING: 'rendering',
    SUCCESS: 'success',
    CANCELED: 'canceled',
    FAILED: 'failed',
    COMPLETED: 'completed',
    CREATED: 'created',
    UPLOADING: 'uploading',
    VALIDATING: 'validating',
    BOOTING: 'booting',
    SUSPENDED: 'suspended',
    FINALIZING: 'finalizing',
}

export const statusColors = {
    rendering: 'LAVENDER_BLUE',
    created: 'SATIN_BLACK',
    completed: 'LIME_GREEN',
    success: 'LIME_GREEN',
    canceled: 'SALMON_PINK',
    validating: 'LIGHT_YELLOW',
    validated: 'APRICOT',
    booting: 'NIGHT_FADE',
    failed: 'SALMON_PINK',
    uploading: 'SILVER',
    suspended: 'SMOKY_GRAY',
    finalizing: 'LIGHT_BLUE_GREEN',
    default: 'SALMON_PINK',
}

export const EXTERNAL_URLS = {
    DOCS: 'https://www.renderjuice.com/docs',
    RELEASE_NOTES: 'https://www.renderjuice.com/releases',
    FEEDBACK: 'https://www.feedback.renderjuice.com',
    DOCS_GPU_MINS: 'https://www.renderjuice.com/docs/renderjuice-101/gpu-mins',
}
