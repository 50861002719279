import cn from 'classnames'
import Tooltip from 'components/Tooltip'
import useUser from 'hooks/useUser'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useGetVersionQuery } from 'store/api/metaApi'
import styled from 'styled-components'
import { EXTERNAL_URLS } from '../../constants'
import { toggleSidebar } from '../../store/slices/displaySlice'
import Icon from '../Icon'
import Image from '../Image'
import Theme from '../Theme'
import Dropdown from './Dropdown'
import Help from './Help'
import styles from './Sidebar.module.sass'

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  span {
    svg {
      height: 24px;
      width: 24px;
      &:hover {
        + div {
          visibility: visible;
          opacity: 1;
          transition-delay: 0.5s;
        }
    }
  }
  
`

const navigation = [
    {
        title: 'Home',
        icon: 'home',
        url: '/',
        addLink: '/',
    },
    {
        title: 'Renders',
        icon: 'diamond',
        url: '/renders',
    },
    {
        title: 'Add Ons',
        icon: 'addons',
        url: '/addons',
    },
]

const Sidebar = ({ className, onClose }) => {
    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visible, setVisible] = useState(false)
    const history = useHistory()
    const { isSidebarExpanded } = useSelector((state) => state.display)
    const [shouldFetch, setShouldFetch] = useState(false)
    const { canAccessAddons } = useUser()
    const dispatch = useDispatch()
    const {
        data: versionData,
        error,
        isLoading,
    } = useGetVersionQuery(
        {},
        {
            skip: !shouldFetch,
        },
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldFetch(true)
        }, 2000)
        return () => clearTimeout(timer)
    }, [])

    const filteredNavigation = navigation.filter(
        (item) => item.title !== 'Add Ons' || canAccessAddons,
    )

    return (
        <>
            <div
                className={cn(styles.sidebar, className, {
                    [styles.active]: visible,
                    [styles.inactiveDesktop]: !isSidebarExpanded,
                })}
            >
                <button className={styles.close} onClick={onClose}>
                    <Icon name='close' size='24' />
                </button>
                <div>
                    <Link className={styles.logo} to='/' onClick={onClose}>
                        <Image
                            className={styles.pic}
                            src='/images/logo.png'
                            srcDark='/images/logo.png'
                            alt='Core'
                        />
                    </Link>
                </div>
                <div className={styles.menu}>
                    {filteredNavigation.map((x, index) =>
                        x.url ? (
                            <NavLink
                                className={styles.item}
                                activeClassName={styles.active}
                                to={x.url}
                                key={index}
                                exact
                                onClick={onClose}
                            >
                                <StyledTooltip
                                    delay={'0.5s'}
                                    className={styled.tooltip}
                                    title={x.title}
                                    place={'bottom'}
                                />
                                <Icon name={x.icon} size='24' />
                                {x.title}
                                {x.addLink && (
                                    <div className={styles.add} to={x.addLink}>
                                        <Icon name='plus' size='10' />
                                    </div>
                                )}
                            </NavLink>
                        ) : (
                            <Dropdown
                                className={styles.dropdown}
                                visibleSidebar={visible}
                                setValue={setVisible}
                                key={index}
                                item={x}
                                onClose={onClose}
                            />
                        ),
                    )}
                </div>
                <button
                    className={styles.toggle}
                    onClick={() => setVisible(!visible)}
                >
                    <Icon name='arrow-right' size='24' />
                    <Icon name='close' size='24' />
                </button>
                <button
                    className={`${styles.link} ${styles.toggleDesktop}`}
                    onClick={() => {
                        dispatch(toggleSidebar())
                    }}
                >
                    <StyledTooltip
                        className={styled.tooltip}
                        title={isSidebarExpanded ? 'Close' : 'Expand'}
                        place={'bottom'}
                    />
                    {isSidebarExpanded ? (
                        <Icon name='arrow-left' size='24' />
                    ) : (
                        <Icon name='arrow-right' size='24' />
                    )}
                    Collapse
                </button>

                <div className={styles.foot}>
                    <a
                        className={styles.link}
                        rel='noreferrer'
                        href={EXTERNAL_URLS.DOCS}
                        target='_blank'
                    >
                        <StyledTooltip
                            className={styled.tooltip}
                            title={'Docs'}
                            place={'bottom'}
                        />
                        <Icon
                            viewBoxWidth='24'
                            viewBoxHeight='24'
                            fillRule={'evenodd'}
                            clipRule={'evenodd'}
                            name='book'
                            size='24'
                        />
                        Documentation
                    </a>
                    <a
                        className={styles.link}
                        rel='noreferrer'
                        href={EXTERNAL_URLS.FEEDBACK}
                        target='_blank'
                    >
                        <StyledTooltip
                            className={styled.tooltip}
                            title={'Feedback'}
                            place={'bottom'}
                        />
                        <Icon
                            viewBoxWidth='24'
                            viewBoxHeight='24'
                            name='mission'
                            size='24'
                        />
                        Give Feedback
                    </a>
                    <button
                        className={styles.link}
                        onClick={() => history.push('/upgrade-to-pro')}
                    >
                        <StyledTooltip
                            className={styled.tooltip}
                            title={'Upgrade'}
                            place={'bottom'}
                        />
                        <Icon name='lightning' size='24' />
                        Upgrade Plan
                    </button>
                    {/*
          <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button>
          */}
                    <Theme
                        className={styles.themeDesktop}
                        invisibleSidebar={!isSidebarExpanded}
                    />
                    <Theme className={styles.theme} visibleSidebar={visible} />
                    <div className={styles.versionInfo}>
                        <a
                            href={EXTERNAL_URLS.RELEASE_NOTES}
                            className={styles.versionInfoLink}
                        >
                            {versionData?.client || ''}
                        </a>
                    </div>
                </div>
            </div>
            {/* <Help
                visible={visibleHelp}
                setVisible={setVisibleHelp}
                onClose={onClose}
            /> */}
            <div
                className={cn(styles.overlay, { [styles.active]: visible })}
                onClick={() => setVisible(false)}
            />
        </>
    )
}

export default Sidebar
